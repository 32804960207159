import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Contact = () => {
    return (
            <div className='h-screen flex flex-col justify-between'>
                <div>
                    <Header centerText="Contact" rtext="Home" ltext="Courses" rpath="/" lpath="/courses" />
                </div>
                <main className='flex md:flex-row flex-col justify-center items-center md:gap-56  '>
                   <div className=' m'>
                   <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15542.840285349132!2d80.1000527!3d13.1175445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5263000529056f%3A0x175b13eb7fda6f71!2sMatrix%20Code!5e0!3m2!1sen!2sin!4v1709304090301!5m2!1sen!2sin" className='map'  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                   </div>
                   <div className='text-white md:w-auto w-screen px-6 md:px-0 py-4 md:py-0'>
                    <h1 className='text-white text-2xl font-semibold'>matrixcode.in</h1>
                    <p className='py-2'>Kamaraja Nagar, Thirumalai Rajapuram, <br /> Avadi, Tamil Nadu - 600054</p>
                    <a href="mailto:matrixcodeofficial@gmail.com">matrixcodeofficial@gmail.com</a>
                    <p className='py-2'>
                    <a href="tel:+918610852281">+91 8610852281</a>
                    </p>
                   </div>
                </main>
                <Footer />
            </div>
    )
}

export default Contact