import React from 'react'

const NoPage = () => {
  return (
    <div className='h-screen flex items-center flex-col gap-4 justify-center'>
        <h1 className='text-white md:text-9xl text-4xl'>404 Not Found</h1>
        <a href="/" className='text-white bg-green rounded px-6 py-2'>Home</a>
    </div>
  )
}

export default NoPage