import React from 'react'

const Header = ({ rtext, ltext, rpath, lpath, centerText }) => {
    return (
        <>
            <div className='flex items-center justify-center pt-4'>
                <a href="/demo"><p className='shimmer text-red-500 bg-gray-800 px-4 py-0.5 rounded text-center'>Limited period offer 15% Off on all Courses!<i className="bi bi-arrow-up-right bg-inherit  pl-2"></i></p></a>
            </div>
            <div className='flex justify-between my-6 items-center'>
                <a href={lpath} className='text-white bg-green md:py-4 md:px-6 md:text-base text-sm py-2 px-4 rounded-r-lg md:w-48 w-32'>
                    <i className="bi bi-chevron-left bg-green pr-2"></i>
                    {ltext}
                </a>
                <p className='text-white text-3xl font-semibold text-center'>{centerText}</p>
                <a href={rpath} className='text-white bg-green md:py-4 md:px-6 md:text-base text-sm py-2 px-4 rounded-l-lg md:w-48 flex justify-end w-32'>
                    {rtext}
                    <i className="bi bi-chevron-right bg-green pl-2"></i>
                </a>
            </div>
        </>
    )
}

export default Header