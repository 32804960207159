import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div className='h-screen flex flex-col justify-between'>
    <div>
      
      <Header centerText="Home" ltext="Contact Us" rtext="Courses" lpath="/contact" rpath="/courses" />
    </div>
    <main className='flex flex-col justify-center items-center md:mb-28'>
      <h1 className='text-white font-black md:text-9xl text-6xl'><span className='text-green'>MATRIX</span>CODE</h1>
      <h2 className='text-white text-center md:text-5xl text-xl'>Enter the Matrix, Unlock Your Potential</h2>
    </main>
    <Footer />
  </div>
  )
}

export default Home