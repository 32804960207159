import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

const Courses = () => {

    return (
        <div className='h-screen flex flex-col justify-between'>
            <div>
                <Header centerText="Courses" rtext="Contact Us" ltext="Home" rpath="/contact" lpath="/" />
            </div>
            <main className='flex flex-col md:flex-row justify-center items-center md:mb-26 gap-4'>
                <div className='border rounded p-4 md:order-1 border-gray-600 text-white course-card md:w-1/4 w-full h-full flex flex-col justify-between '>
                    <div>
                        <h2 className='text-4xl font-semibold'>UI/UX Design
                        </h2>
                        <h3 className='text-sm'>Elevate UI/UX through pixel-perfect designs.</h3>
                    </div>
                    <ul className='list-disc p-4'>


                        <li>Master UI design principles and concepts</li>
                        <li>Learn industry-standard design tools (e.g., Adobe XD, Sketch, Figma)</li>
                        <li>Develop responsive and accessible user interfaces</li>
                        <li>Implement user testing and feedback mechanisms</li>
                        <li>Collaborate effectively with developers and stakeholders</li>


                    </ul>
                    <div>
                        <a href="/demo" className='text-center bg-green w-screen'><p className='bg-inherit py-2 text-lg rounded'>Enter the Matrix</p></a>
                    </div>
                </div>
                <div className='border order-first md:order-2 rounded p-4 border-gray-600 text-white course-card md:w-1/4 w-full  h-full flex flex-col justify-between '>
                    <div>
                    <h2 className='text-4xl font-semibold'>Full-Stack Development
                    </h2>
                    <h3 className='text-sm'>Comprehensive full-stack training.</h3>
                    </div>
                    <ul className='list-disc p-4'>

                        <li>Develop end-to-end web solutions Using React</li>
                        <li>Learn Core Python</li>
                        <li>Gain proficiency in MySQL</li>
                        <li>Implement server-side development with Django</li>
                        <li>Utilize version control using Git & GitHub</li>
                    </ul>
                    <div>
                    <a href="/demo" className='text-center bg-green w-screen'><p className='bg-inherit py-2 text-lg rounded'>Enter the Matrix</p></a>
                    </div>
                </div>
                <div className='border rounded p-4 md:order-3 border-gray-600 text-white course-card md:w-1/4 w-full h-full flex flex-col justify-between '>
                    <div>
                        <h2 className='text-4xl font-semibold'>Web Development
                        </h2>
                        <h3 className='text-sm'>Comprehensive web development training.</h3>
                    </div>
                    <ul className='list-disc p-4'>
                    
                        <li>Frontend Development Basics</li>
                        <li>Responsive Design</li>
                        <li>Version Control and Deployment</li>
                        <li>Web Development Fundamentals</li>
                        <li>Single Page Applications (SPAs)</li>
                    </ul>
                    <div>
                        <a href="/demo" className='text-center bg-green w-screen'><p className='bg-inherit py-2 text-lg rounded'>Enter the Matrix</p></a>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Courses