import React from 'react'
import './App.css'
import Home from './pages/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from './pages/Contact';
import BookDemo from './pages/BookDemo';
import Courses from './pages/Courses';
import NoPage from './pages/NoPage';
import Certificate from './pages/Certificate';

const App = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route index element={<Home />} />
      <Route path="/certificate/:id" element={<Certificate/>} />
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/demo"  element={<BookDemo/>}/>
      <Route path="/courses"  element={<Courses/>}/>
      <Route path="*" element={<NoPage />} />
    </Routes>
  </BrowserRouter>
  )
}

export default App