import Header from '../components/Header'
import Footer from '../components/Footer'
import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const initialFormData = {
  name: '',
  email: '',
  phone: '',
  language: '',
  location: '',
  mode: '',
  dob: '',
  course: '',
}

const BookDemo = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'webContact'), { formData })

      setAlert({ type: 'success', message: 'Demo booked successfully!' });
      setFormData(initialFormData);
    } catch (error) {
      console.log(error)
      setAlert({ type: 'danger', message: 'An error occurred. Please try again.' });
    }
  };

  return (
    <div className='h-screen flex flex-col justify-between'>
      <div>

        <Header centerText="Demo" rtext="Home" ltext="Courses" rpath="/" lpath="/courses" />
      </div>
      <main className='flex flex-col justify-center items-center md:mb-28'>
        <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="name" className="block text-white font-semibold">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border text-white border-gray-300 rounded-md focus:outline-none focus:border-green-500"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-white font-semibold">Email</label>
            <input

              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border text-white border-gray-300 rounded-md focus:outline-none focus:border-green-500"
              required
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-white font-semibold">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border text-white border-gray-300 rounded-md focus:outline-none focus:border-green-500"
              required
            />
          </div>
          <div>
            <label htmlFor="location" className="block text-white font-semibold">Location</label>
            <input
              required
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className="w-full px-3 py-2 text-white border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
            />
          </div>
          <div className="">
            <label htmlFor="language" className="block text-white font-semibold">Preferred language</label>
            <select
              required
              id="language"
              name="language"
              value={formData.language}
              onChange={handleChange}
              className="w-full px-3 py-2 border text-white border-gray-300 rounded-md focus:outline-none focus:border-green-500"
            >
              <option value="">Select a language</option>
              <option value="tamil">Tamil</option>
              <option value="hindi">Hindi</option>
              <option value="english">English</option>
            </select>
          </div>

          <div>
            <label htmlFor="mode" className="block text-white font-semibold">Preferred mode</label>
            <select
              required
              id="mode"
              name="mode"
              value={formData.mode}
              onChange={handleChange}
              className="w-full px-3 py-2 border text-white border-gray-300 rounded-md focus:outline-none focus:border-green-500"
            >
              <option value="">Select mode</option>
              <option value="Online">Online</option>
              <option value="Offline">Offline</option>
            </select>
          </div>
        
          <div>
            <label htmlFor="dob" className="block text-white font-semibold">Date of Birth</label>
            <input
              required
              type="date"
              id="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              className="w-full px-3 py-1.5 text-white border border-gray-300 rounded-md focus:outline-none focus:outline-green-500"
              />
          </div>
          <div>
            <label htmlFor="course" className="block text-white font-semibold">Select Course</label>
            <select
              required
              id="course"
              name="course"
              value={formData.course}
              onChange={handleChange}
              className="w-full px-3 py-2 text-white border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
            >
              <option value="">Select a course</option>
              <option value="Python Full Stack">Python Full Stack</option>
              <option value="UI/UX">UI/UX</option>
              <option value="Java Software Testing">Java Software Testing</option>
            </select>
          </div>
          <div className="text-center">
            <div className={`alert text-white alert-${alert.type}`} role="alert">
              {alert.message}
            </div>
          </div>
          </div>
            <button
              type="submit"
              className="w-full px-6 mb-2 md:mb-0 py-2 bg-green-600 text-white rounded-md hover:bg-green-500 transition duration-300 ease-in-out"            >
              Book Free Demo
            </button>
        </form>
      </main>
      <Footer />
    </div>
  )
}

export default BookDemo