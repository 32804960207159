import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const Certificate = () => {
  const [certificateUrl, setCertificateUrl] = useState('');
  const { id } = useParams(); // Extracting id from URL
  // gs://matrixcode-4e8a3.appspot.com/03YMmp3MhOGgmXB8kUvo.png
  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        // Reference to the image in Firebase Storage
        const imageRef = ref(storage, `gs://matrixcode-4e8a3.appspot.com/${id}.png`); // Corrected usage of ref()

        // Get the download URL for the image
        const url = await getDownloadURL(imageRef); // Corrected usage of getDownloadURL()
        // Set the URL to state
        setCertificateUrl(url);
      } catch (error) {
        console.error('Error fetching certificate:', error);
        // Handle error appropriately, like showing a message to the user
      }
    };

    fetchCertificate();
  }, [id]);

  return (
    
    <div className='h-screen flex flex-col justify-between'>
    <div>
        <Header centerText="Certificate" rtext="Home" ltext="Courses" rpath="/" lpath="/courses" />
    </div>
    <main className='flex md:flex-row flex-col justify-center items-center md:gap-56  '>
    <div className='text-white flex justify-center'>
      {certificateUrl ?
      
      // <div className="flex items-center w-100">
        <img className='md:w-1/2 w-screen h-auto' src={certificateUrl} alt="Certificate" />
      // </div>
     
      :<>
      <div className=' flex items-center flex-col gap-4 justify-center'>
        <h1 className='text-white md:text-9xl text-4xl'>404 Not Found</h1>
        <a href="/" className='text-white bg-green rounded px-6 py-2'>Home</a>
    </div>
       </>}
    </div>
    </main>
    <Footer />
</div>
  );
};

export default Certificate;