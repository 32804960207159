import React from 'react'

const Footer = () => {
  return (
    <div className='py-2 md:px-12 flex md:flex-row flex-col items-center justify-between px-2'>

      <div className='flex gap-x-4 items-center'>
        <a href="https://www.instagram.com/matrixcodeofficial/" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram text-lg text-white hover:text-pink-500"></i></a>
        <i className="bi bi-twitter-x text-lg text-white hover:text-gray-400"></i>
        <a href="https://wa.me/8610852281" target='_blank'><i className="bi bi-whatsapp text-lg text-white hover:text-green-500"></i></a>
        <a href="mailto:matrixcodeofficial@gmail.com"><i className="bi bi-envelope text-2xl text-white hover:text-red-500"></i></a>
      </div>
      <div className='flex gap-1 justify-center md:justify-end w-full md:text-base text-sm'>
        <p className='text-white text-end'><i className="bi bi-c-circle"></i> <span>
          Matrixcode.in - All rights reserved </span> </p>
      </div>
      <div>
      </div>
    </div>
  )
}

export default Footer